/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Satisfly/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getCategories() {
        return new Field('categories_matched').addFieldList(['name', 'url']);
    }

    _getProductFields() {
        const {
            categories, requireInfo, isSingleProduct, notRequireInfo
        } = this.options;

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            const items = [
                this._getItemsField()
            ];

            if (categories) {
                return [...items, this._getCategories()];
            }

            return items;
        }

        // for filters only request
        if (requireInfo) {
            return [
                this._getSortField(),
                this._getAggregationsField()
            ];
        }

        return [
            'total_count',
            this._getItemsField(),
            this._getPageInfoField()
        ];
    }

    _getMinimalPriceFields(isCart) {
        if (isCart) {
            return [
                this._getDiscountField(),
                this._getRegularPriceField(),
                this._getRegularPriceExclTaxField()
            ];
        }

        return [
            this._getDiscountField(),
            this._getFinalPriceField(),
            this._getFinalPriceExclTaxField(),
            this._getRegularPriceField(),
            this._getRegularPriceExclTaxField(),
            this._getDefaultPriceField(),
            this._getDefaultFinalPriceField(),
            this._getDefaultFinalPriceExclTaxField()
        ];
    }

    _getMaximalPriceField(isCart) {
        return new Field('maximum_price')
            .addFieldList(this._getMinimalPriceFields(isCart));
    }

    _getPriceRangeFields(isCart) {
        if (isCart) {
            return [
                this._getMaximalPriceField(isCart)
            ];
        }

        return [
            this._getMinimalPriceField(),
            this._getMaximalPriceField()
        ];
    }

    _getPriceRangeField(isCart = false) {
        return new Field('price_range')
            .addFieldList(this._getPriceRangeFields(isCart));
    }

    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField(),
            this._getPriceRangeField(true)
        ];
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        // Basic fields returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'salable_qty',
            'is_product_disabled',
            this._getStockItemField(),
            this._getPriceRangeField()
        ];

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'benefit_block',
                'special_offer_description',
                'special_offer_description_visable',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this.getProductDetailsTabContentAttribute(),
                this.getProductCareStepByStepTabContentAttribute(),
                this.getProductAttachmentsTabContentAttribute(),
                this.getProductActiveIngredientsTabContentAttribute(),
                this.getProductExpertAdvisesTabContentAttribute(),
                this.getProductInciTabContentAttribute()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

    getProductDetailsTabContentAttribute() {
        return new Field('details_attribute').addFieldList(['html']);
    }

    getProductCareStepByStepTabContentAttribute() {
        return new Field('care_step_by_step_attribute').addFieldList(['html']);
    }

    getProductAttachmentsTabContentAttribute() {
        return new Field('attachments_attribute').addFieldList(['html']);
    }

    getProductActiveIngredientsTabContentAttribute() {
        return new Field('active_ingredients_attribute').addFieldList(['html']);
    }

    getProductExpertAdvisesTabContentAttribute() {
        return new Field('expert_advises_attribute').addFieldList(['html']);
    }

    getProductInciTabContentAttribute() {
        return new Field('inci_attribute').addFieldList(['html']);
    }

    _getAggregationsOptionsFields() {
        return [
            'label',
            'count',
            'url',
            new Field('value').setAlias('value_string'),
            this._getSwatchDataField()
        ];
    }

    _getAttributeFields(isVariant = false, isCart = false) {
        return [
            'attribute_id',
            'attribute_value',
            'attribute_code',
            'attribute_label',
            'used_in_product_listing',
            ...this._getAdditionalAttributeFields(isCart),
            ...this._getAttributeOptionsFields(isVariant)
        ];
    }
}

export default new ProductListQuery();

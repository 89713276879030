/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.config';

export const SHOW_MORE_LESS_CONTENT_CONSENT_LENGTH = 169;
export const MANUAL_MODE = 'MANUAL';
export const DEFAULT_BEAUTY_STUDIO_PKD = '96.02';
export const CONSENT_TO_EMAIL_COMMUNICATIONS = 'Zgoda na komunikacje elektroniczną';
export const CONSENT_TO_TELEPHONE_CONTACT = 'Zgoda na kontakt telefoniczny';
